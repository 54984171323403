@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
    margin: 0 10px;
}

.no-spinner {
    -moz-appearance: textfield;
  }
  
  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }